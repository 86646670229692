import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { Flex } from 'grid-styled'
import styled from "styled-components"
import { TextSemi } from 'components/text'
import CloseButton from './CloseButton'
import { Wrapper, WhiteBox } from './styled'

const MODAL_NODE = document.getElementById('modal')

const ModalActionsContainer = styled(Flex)`
  justify-content: flex-end;
  gap: 10px;
`;

// Set margin here since the styled component ignores it in the parent
export const ModalActions = ({ children, mt = "20px", ...props }) => (
  <ModalActionsContainer mt={mt} {...props}>{children}</ModalActionsContainer>
);

class Modal extends React.Component {
  componentDidMount() {
    document.body.classList.add('no-scroll')
    document.addEventListener('keydown', this.handleKeydown)
    document.addEventListener('click', this.handleOutsideMouseClick)
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll')
    document.removeEventListener('keydown', this.handleKeydown)
    document.removeEventListener('click', this.handleOutsideMouseClick)
  }

  handleOutsideMouseClick = ({ target }) => {
    target === this.wrapper && this.props.onClose && this.props.onClose()
  }

  handleKeydown = ({ keyCode }) => {
    keyCode === 27 && this.props.onClose()
  }

  render() {
    const { title, onClose } = this.props
    const modal = (
      <Wrapper innerRef={ref => (this.wrapper = ref)}>
        <WhiteBox>
          {(title || onClose) && (
            <Flex justifyContent="space-between" alignItems="center" mb={20}>
              {title && <TextSemi fontSize="2.2rem">{title}</TextSemi>}
              {onClose && (
                <CloseButton data-testid="modalCloseButton" onClick={onClose} />
              )}
            </Flex>
          )}
          {this.props.children}
        </WhiteBox>
      </Wrapper>
    )

    return createPortal(modal, MODAL_NODE)
  }
}

Modal.propTypes = {
  title: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Modal
